.container-fluid {
  padding: 20px;
  width: 600px;
  height: 100vh;
}
.header {
  margin-top: 50px;
}
.img-logo {
  height: 100px;
  width: 100px;
  margin-top: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.fa-solid {
  cursor: pointer;
}
.loading {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.4);
  z-index: 1000;
}

.loading-icon {
  color: black;
  position: fixed;
  top: 40%;
  left: 46%;
  width: 100px;
  height: 100px;
}
.tbl {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}


@media only screen and (max-width: 600px) {
  .container-fluid {
    width: 100%;
  }
  .header {
    margin-top: 20px !important;
  }
  .tbl {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .img-logo {
    height: 70px;
    width: 70px;
  }
  .loading-icon {
    left: 38%;
  }
}